.home-dashboard-wrapper,
.limited-home-dashboard-wrapper {
  padding-bottom: $action-button-height;

  @include tablet {
    padding-bottom: $action-button-height-tablet;
  }

  @include tablet-landscape {
    padding-bottom: 5px;
  }

  &:before {
    content: '';
    position: fixed;
    top: 44px;
    left: 0px;
    right: 0px;
    height: 160px;
    width: 100%;
    background: var(--ion-color-dark);

    @include tablet {
      height: 275px;
      top: 56px;
    }
  }

  &.no-margin-top {
    margin-top: 0;
  }
}

.margin-top-20 {
  margin-top: 20px;
}

//Metric List
.metrics-list {
  background: transparent;
  padding-top: 7px;
  margin-bottom: 8px;

  ion-item {
    --background: transparent;
    --padding-start: 0px;
    --inner-padding-end: 0px;
    border-bottom: 1px solid #666666;
    --border-style: none;

    &:last-child {
      border: none;
    }

    p {
      font-size: 1rem;
      font-weight: $bold;
      padding-right: 8px;
      margin-bottom: 0px;
    }
  }
}

//Metric Card List
.layout-content .metrics-card-list {
  background: transparent;
  display: flex;
  gap: 14px;
  padding: 0px;

  ion-card {
    background: var(--ion-color-secondary);
    border: none;
    text-align: center;
    width: 100%;

    &:hover {
      background: #d6a32c;
    }

    ion-card-content {
      padding: 14px 16px;
    }

    ion-card-title,
    p,
    ion-card-subtitle {
      color: var(--ion-color-base);
      font-weight: $bold;
    }

    ion-card-title {
      font-size: 0.85rem;
      text-transform: uppercase;
    }

    p {
      font-size: 1.3rem;
      line-height: 1.5;

      @include tablet {
        font-size: 1.9rem;
      }
    }

    ion-card-subtitle {
      font-size: 0.75rem;
    }
  }
}

//Mileage Card
ion-router-link.mileage-button {
  --background: var(--ion-color-green);
  border-radius: 8px;
  box-sizing: border-box;
  --color: #ffffff;
  margin-bottom: 14px;
  padding: 23px 13px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: var(--ion-color-green-shade);
    opacity: 1;
  }

  ion-icon {
    height: 24px;
    margin-right: 4px;
    vertical-align: sub;
    width: 24px;
  }
}

ion-card.documents-list-card {
  ion-card-title {
    font-weight: normal;
    font-size: 14px;
    text-transform: uppercase;
  }

  ion-card {
    border: none;
  }

  ion-card-content {
    border-radius: 10px;
    box-shadow: none;
  }

  ion-list {
    background: var(--ion-color-light);

    ion-item {
      --background: var(--ion-color-light);
      --padding-start: 0px;
      --inner-padding-end: 0px;
      --background-hover: white;

      &:hover {
        .table-content-line-item-title {
          text-decoration: underline;
        }
      }
    }
  }

  ion-router-link {
    display: block;
    font-size: 16px;
    margin-top: 10px;
    text-decoration: underline;
  }
}

// Hub Messages
.hub-messages-notification-card {
  --background: #c5dbff;
  margin: 0px 0px 14px 0px;
  border-radius: 10px;
  box-shadow: none;

  &::part(native) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ion-card-header {
    padding-bottom: 6px;
  }

  ion-card-content {
    color: var(--ion-color-dark);
  }

  ion-button[fill='outline'] {
    background-color: transparent;
    margin-right: 20px;
  }
}

.alert-dot {
  background-color: var(--ion-color-alert);
  border-radius: 20em;
  display: inline-block;
  height: 10px;
  margin-right: 8px;
  width: 10px;
}

//Consultant Card
.consultant-card {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 12px;

  ion-card-header {
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 8px;

    .initials-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: var(--ion-color-secondary);

      span {
        font-size: 1.3rem;
        font-weight: $bold;
        color: var(--ion-text-color);
      }
    }

    ion-card-subtitle {
      text-transform: none;
      font-weight: normal;
      margin: 0;
      color: var(--ion-text-color);
    }

    ion-card-title {
      font-size: 1.1rem;
      font-weight: $bold;

      @include tablet {
        font-size: 1.3rem;
      }
    }
  }

  ion-card-content {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;

    ion-button {
      font-size: 0.85rem;
      text-transform: none;

      ion-icon {
        font-size: 1.3rem;
      }

      @include mobile {
        --padding-start: 0;
        --padding-end: 0;
        --border-radius: 50%;
        aspect-ratio: 1;
      }
    }
  }
}
