.layout-content {
  ion-card:not(.default-card-styles) {
    background: var(--ion-color-light);
    border: 1px solid #dddbda;
    border-radius: 10px;
    box-shadow: none;
    margin: 0px 0px 14px 0px;
  }
}

ion-card:not(.default-card-styles) {
  ion-card-header {
    padding: 12px;
  }

  ion-item.total-row-background {
    --background: var(--ion-color-total-row);
    margin-left: 16px;
    margin-bottom: 12px;
    font-weight: $bold;

    ion-label {
      margin-left: 0px;
    }
  }
}

.tables-with-totals {
  ion-item {
    ion-label {
      margin-left: 16px;
    }

    p {
      margin-right: 16px;
    }
  }
}

ion-card {
  ion-card-header {
    ion-card-title {
      color: black;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  ion-card-content {
    p {
      color: black;
    }

    ion-list {
      ion-item:last-of-type::part(native) {
        border: none;
      }
    }
  }
}

.card-flex {
  @include tablet {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  ion-card-header,
  ion-card-content {
    padding: 0px;
  }

  ion-card-header + ion-card-content {
    margin-top: 12px;
  }

  .items-flex {
    align-items: center;
    display: flex;
    margin-top: 12px;

    @include tablet {
      margin-top: 0px;
    }

    ion-img {
      height: 52px;
    }
  }
}

ion-card {
  &.centered {
    text-align: center;

    ion-card-content {
      margin: 0 auto;
      max-width: 600px;
      padding: 0px;
    }
  }

  &.ion-padding-small {
    padding: 28px 16px;

    ion-card-header {
      padding: 0px;
      margin-bottom: 16px;
    }
  }

  &.ion-padding-medium {
    padding: 62px 16px;
  }

  ion-card-content {
    p + ion-router-link {
      margin-top: 16px;
    }
  }
}
