// Criteria Form
.criteria-form-flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 16px;
  align-items: end;
  width: 100%;

  @include tablet-landscape {
    flex-direction: row;
    margin-bottom: 0px;
  }

  .right-content {
    display: flex;
    gap: 6px;
    width: 100%;

    @include tablet-landscape {
      margin-bottom: 16px;
      width: auto;
    }

    ion-button {
      min-height: 44px;
      margin: 0px;
      width: 100%;

      @include tablet-landscape {
        width: auto;
      }
    }

    ion-button[fill='outline'] {
      background-color: var(--ion-color-primary-contrast);
    }
  }

  p {
    font-weight: var(--ion-font-weight-bold);
    width: 100%;
    margin-bottom: 0px;
    text-align: right;

    @include tablet {
      margin-bottom: 16px;
      width: auto;
    }
  }
}

.mode-selector {
  display: flex;
  gap: 6px;

  ion-button {
    &.button-has-icon-only {
      --padding-start: 0.5em;
      --padding-end: 0.5em;
      height: 44px;
      width: 44px;
    }
  }
}

//Desktop Table
.table-section-wrapper {
  ion-card-title {
    font-size: 1rem;
    font-weight: var(--ion-font-weight-bold);
    justify-content: start;
    margin: 0px;
    padding-left: 12px;
    width: 100%;
  }

  .table-cell-align-center {
    display: flex;
    align-items: center;

    ion-icon {
      cursor: pointer;
      margin-right: 12px;
      flex-shrink: 0;
    }
  }

  td {
    &:first-child ion-item {
      font-size: 0.875rem;
    }

    &.MuiTableCell-root {
      font-family: $primary-font;
      padding: 6px 12px;
    }

    ion-item {
      --border-color: transparent;
      --background: transparent;
      --background-hover: transparent;
      --padding-start: 0px;
      --inner-padding-end: 0px;
      --min-height: auto;

      &:hover {
        color: var(--ion-color-dark);
      }
    }
  }

  .clickable-cell {
    cursor: pointer;
    text-decoration: underline;
  }
}

//Mobile Table
ion-segment + .table-section-wrapper {
  margin-top: 14px;
}

.table-section-wrapper.ion-hide-md-up {
  ion-card {
    ion-card-header {
      padding: 14px;
    }
    ion-card-title {
      font-size: 1rem;
      padding: 0px;
      margin: 0px;
    }

    table {
      table-layout: fixed;

      tr {
        td:first-child {
          width: 70%;

          ion-item {
            width: 100%;
          }
        }

        td:last-child {
          width: 30%;
        }
      }
    }

    thead {
      display: none;
    }

    ion-card-content {
      padding: 0px 14px 14px;

      td:first-child ion-item {
        font-size: 0.9rem;
        width: 80%;

        ion-icon {
          display: none;
        }
      }
    }
  }
}

.table-section-wrapper {
  .card-content-md {
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

// List Style Table Desktop
ion-list.list-style-table {
  background: transparent;
  padding: 1px;
  width: 100%;
  margin-bottom: 60px;

  .list-style-table-header {
    border: 1px solid #dddbda;
    border-bottom: none;
    --border-color: transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    --min-height: 40px;
    --padding-top: 10px;
    --padding-end: 16px;
    margin: 0px -2px 0px 0px;
    width: calc(100% - 2px);

    ion-col {
      color: #757575;
    }

    ion-grid {
      padding: 0px;
    }
  }

  .pagination-items-wrapper {
    width: 100%;

    ion-item {
      border-left: 1px solid #dddbda;
      border-right: 1px solid #dddbda;
      --padding-top: 0px;
      --padding-end: 16px;

      &:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        --border-color: transparent;
        position: relative;
        border-bottom: 1px solid #dddbda;
      }
    }
  }
}

// List Style Table Mobile
.list-style-table-card {
  width: 100%;
  min-width: 340px;

  ion-item {
    --border-color: #dddbda;
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    margin-bottom: 0px;

    &:last-child {
      --border-color: transparent;
    }

    p {
      text-align: right;
    }
  }

  .align-center {
    text-align: center;
    margin-bottom: 15px;
  }

  p,
  a {
    margin-bottom: 0px;
  }

  &:last-of-type:not(.no-bottom-margin) {
    margin-bottom: 80px;
  }
}

//Empty State
.no-table-data {
  padding: 20px 20px 10px;
}

// Sticky Balance
.custom-ending-balance-toast {
  height: 59px;
  width: calc(100% - 72px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  color: var(--ion-color-primary-contrast);
  padding: 0px 36px;
  background-color: var(--ion-flow-background);
  border-radius: 12px;

  &.fixed {
    position: fixed;
    bottom: 20px;
    width: calc(100% - 110px);

    @include tablet {
      width: calc(100% - 140px);
    }

    @include tablet-landscape {
      max-width: calc(1040px - 88px);
      bottom: 60px;
    }
  }
}

// Benchmarks
.benchmark-icon {
  font-size: 1.4rem;
  margin-left: 3px;
}
