.item-link {
  --ion-item-background: var(--ion-color-primary-contrast);
  --background-hover: var(--ion-color-primary-contrast);
}

ion-button[color='default'] {
  --ion-color-base: var(--ion-color-secondary) !important;
  --color: black;

  &:hover {
    --ion-color-base: #d6a32c !important;
  }
}

ion-button {
  --box-shadow: none;
  --border-radius: 8px;
  letter-spacing: 0px;
  font-size: 1rem;
  font-weight: $bold;
  min-height: 48px;
  text-transform: capitalize;
}

ion-button[fill='outline']:not([color='light']) {
  background-color: var(--ion-color-primary-contrast);
  --border-width: 1px;
  border-radius: 8px;
}

ion-button[type='submit'] {
  appearance: none;
}

ion-router-link {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

ion-router-link {
  margin-top: 24px;
  display: block;
  width: auto;
  text-decoration: underline;
  font-weight: $bold;
  text-transform: capitalize;
  font-size: 1rem;
  margin: 0 auto;
  text-align: center;
  position: relative;

  &:hover {
    opacity: 0.8;
  }

  &.minimal {
    font-weight: $regular;
  }

  &.button-style {
    --background: none;
    border: 1px solid var(--ion-color-base);
    color: var(--ion-color-base);
    border-radius: 8px;
    box-sizing: border-box;
    margin-bottom: 14px;
    padding: 11px 1.1rem;
    text-align: center;
    text-decoration: none;
    width: auto;
    margin: auto 2px;
    transition: none;

    &:hover {
      background-color: rgba(var(--ion-color-base-rgb), 0.04);
      opacity: 1;
    }
  }
}

ion-card-content {
  p ion-router-link {
    display: inline-block;
    font-weight: $regular;
    font-size: 0.875rem;
  }

  ion-router-link {
    margin: 0px;
  }

  ion-router-link + ion-router-link {
    margin-top: 16px;
  }
}

.button-large {
  --padding-top: 23px;
  --padding-start: 13px;
  --padding-end: 13px;
  --padding-bottom: 23px;
  box-sizing: border-box;
  height: 72px;
}

ion-button.ion-color-success {
  --ion-color-base: var(--ion-color-green) !important;
  --ion-color: var(--ion-color-success-contrast) !important;

  &:hover {
    --ion-color-base: var(--ion-color-green-shade) !important;
  }
}

ion-button.ion-color-danger {
  &:hover {
    --ion-color-base: var(--ion-color-danger-shade) !important;
  }
}

ion-button.text-button {
  --background: none;
  color: var(--ion-color-base);
  text-decoration: underline;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
  &:hover {
    color: var(--ion-color-base-light);
  }
}

.action-button {
  right: 0px;
  left: 0px;
  bottom: 10px;
  max-width: 1040px;
  margin-inline: 0;
  margin: 0 20px;
  position: fixed;
  height: $action-button-height;

  @include tablet {
    height: $action-button-height-tablet;
    margin: 0 36px;
    padding: 0px;
  }

  @include tablet-landscape {
    padding: 0px;
    margin: 10px 0px 0px 0px;
    position: relative;
    width: 100%;
  }
}
